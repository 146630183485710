import Splide from '@splidejs/splide';
import Helper from '../../../../common/utils/helper';

export default class Home {
    constructor() {
        this.init();
    }

    init() {
        Home.initCarouselPaginator();
        Home.initInspirationsCategoriesListeners();
        Home.actionListener();
        if (document.querySelector('body:not(.editorial)')) {
            Home.displaySubMenu();
        }
        const slides = document.querySelectorAll('.splide.anotherSplide');
        slides.forEach((slide) => {
            new Splide('#' + slide.getAttribute('id'), {
                perPage: 4,
                omitEnd: true,
                autoplay: true,
                gap: '1rem',
                breakpoints: {
                    1084: {perPage: 3},
                    750: {
                        fixedWidth: '16rem',
                    },
                },

            }).mount();
        });
    }

    /**
     * Chargement d'un comportement de mise à jour du style du paginateur de slide qui était auparavant
     * caché et non dynamique. On passe en gras (active) le numéro de slide affiché.
     * Fonctionne sur site editorial et ecommerce
     */
    static initCarouselPaginator() {
        const myCarousel = document.getElementById('carouselHome');

        myCarousel.addEventListener('slide.bs.carousel', (event) => {
            // Récupération du bloc paginateur
            const paginator = myCarousel.querySelector('.slider-pagination');
            if (paginator) {
                const slidePosition = event.to;
                // Désactivation de tous les items de paginateurs
                paginator.querySelectorAll('.slider-pagination-item').forEach((item) => {
                    item.classList.remove('active');
                });
                // Activation de l'item de paginateur qui correspond à la slide actuellement affichée
                const currentPaginatorItemLink = paginator.querySelector(`.slider-pagination-item a[data-bs-slide-to="${slidePosition}"]`);
                if (currentPaginatorItemLink) {
                    // On récupère le li parent du lien et on le passe en active
                    currentPaginatorItemLink.parentElement?.classList.add('active');
                }
            }
        });
    }

    static initInspirationsCategoriesListeners() {
        document.querySelectorAll('.inspirations-categories .category').forEach((category) => {
            category.addEventListener('click', () => {
                fetch(category.getAttribute('data-url') ?? '', {
                    method: 'GET',
                    headers: Helper.createFetchHeader(),
                })
                    .then((response) => Promise.all([response, response.text()]))
                    .then(([, data]) => {
                        Home.disableActiveCategory();
                        category.classList.add('active');
                        const realisations = document.querySelector('.container-realisation');
                        if (realisations) {
                            realisations.innerHTML = data;
                        }
                    });
            });
        });
    }

    static disableActiveCategory() {
        const category = document.querySelector('.inspirations-categories .category.active');
        if (category) {
            category.classList.remove('active');
        }
    }

    static actionListener() {
        /* display / hide actions button */
        window.addEventListener('scroll', () => {
            if (document.getElementById('home-testimonials') != null) {
                const testimonials = document.getElementById('home-testimonials');
                if (testimonials) {
                    const positionSection = testimonials.getBoundingClientRect();
                    const height = positionSection.height * -1;
                    const actions = document.querySelector('#actions');

                    if (actions) {
                        if ((positionSection.top < 1 && positionSection.top > height)
                            || (positionSection.top < 1 && positionSection.top < height)) {
                            actions.classList.add('d-none');
                            actions.classList.remove('d-flex');
                        } else if (positionSection.top < height || positionSection.top > 1) {
                            actions.classList.add('d-flex');
                            actions.classList.remove('d-none');
                        }
                    }
                }
            }
        });
    }

    static displaySubMenu() {
        const menu = document.querySelector('.desktop-nav .categories-item ul.dropdown-menu');
        if (menu) {
            menu.classList.add('show');
        }
        const firstLink = document.querySelector('#dropdownMenuLink');
        if (firstLink) {
            firstLink.classList.add('show');
        }
    }
}

// On document ready
document.addEventListener('DOMContentLoaded', () => {
    (() => new Home())();
});
