type ToastType = 'primary'|'secondary'|'success'|'danger'|'warning'|'info'|'light'|'dark';

export default class Helper {
    static createFetchHeader() {
        return {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': (document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content,
            'X-Requested-With': 'XMLHttpRequest',
        };
    }

    static createFetchHeaderWithoutContentType() {
        return {
            Accept: 'application/json',
            'X-CSRF-TOKEN': (document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content,
            'X-Requested-With': 'XMLHttpRequest',
        };
    }

    static replaceClass(el: HTMLElement, oldClass: string, newClass: string) {
        el.classList.remove(oldClass);
        el.classList.add(newClass);
    }

    static getElementPreviousSibilings(element: Element) {
        // Setup siblings array and get next sibling
        const siblings: Element[] = [];
        let prev = element.previousElementSibling;

        // Loop through all siblings
        while (prev) {
            // Otherwise, push to array
            siblings.push(prev);
            // Get the previous sibling
            prev = prev.previousElementSibling;
        }

        return siblings;
    }

    static getElementNextSibilings(element: Element) {
        // Setup siblings array and get next sibling
        const siblings: Element[] = [];
        let next = element.nextElementSibling;

        // Loop through all siblings
        while (next) {
            // Otherwise, push to array
            siblings.push(next);
            // Get the next sibling
            next = next.nextElementSibling;
        }

        return siblings;
    }

    static isReload() {
        return window.performance
            .getEntriesByType('navigation')
            .map((nav:PerformanceNavigationTiming) => nav.type)
            .includes('reload');
    }

    static removeElementContent(element: HTMLElement) {
        while (element.firstChild) {
            element.firstChild.remove();
        }
    }

    static createToastElement(message: string, type: ToastType) {
        const toastElement = document.createElement('div');
        toastElement.setAttribute('class', 'toast-container position-fixed top-0 start-50 translate-middle-x');
        toastElement.innerHTML = `
            <div class="toast align-items-center text-bg-${type} border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="d-flex">
                    <div class="toast-body">
                        ${message}
                    </div>
                    <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
            </div>`;
        document.body.appendChild(toastElement);
        const toast = window.bootstrap.Toast.getOrCreateInstance(toastElement.querySelector('.toast') ?? toastElement);
        Helper.initHideEvents(toastElement, 'toast');
        toast.show();
    }

    static initHideEvents(element: HTMLElement, type: 'modal' | 'toast') {
        element.addEventListener(`hidden.bs.${type}`, () => {
            element?.remove();
        });
    }
}
